<template>
  <div class="login-wrapper" :style="`pointer-events: ${isLoading ? 'none' : ''}`">
    <div v-if="isLoading" class="loading-screen">
      <div class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <div class="div-header position-absolute">
      <img
        alt="Panasonic Logo"
        class="nav-logo"
        contain
        src="@/assets/logo-panasonic.svg"
      />
    </div>
    <div class="account-pages">
      <div class="container">
        <div class="row align-items-center justify-content-center card-login" style="min-height: 500px;">
          <div class="col-md-8 col-lg-6 col-xl-5 col-card-login">
            <div class="card card-login card-login-width">
              <v-row no-gutters style="background-color: #6b6b6b;">
                <v-col cols="12">
                  <div class="app-title p-2">
                    <div class="middle login-heading">Smart Energy Management Platform</div>
                  </div>
                </v-col>
              </v-row>
              <div class="card-body shadow p-4 login-p-4">
                <v-form
                  ref="loginForm"
                  @submit.prevent="submit"
                  v-model="valid"
                  autocomplete="off"
                >
                  <v-row no-gutters class="pt-2">
                    <v-col cols="3" class="login-label login-col-3">
                      <span class="login-color login-title">Username:</span>
                    </v-col>
                    <v-col cols="9" class="login-col-9">
                      <v-text-field
                        label=""
                        class="login-input bg-login-input input-text"
                        v-model="userName"
                        required
                        outlined
                        filled
                        single-line
                        placeholder="username"
                        :rules="userValidation"
                        autocomplete="off"
                      ></v-text-field>
                      <div
                        class="error-message error-message-pink error-message-size"
                        v-if="this.errUserCount > 0"
                      >
                        {{ userValidationMsg }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="3" class="login-label login-col-3">
                      <span class="login-color login-title">Password:</span>
                    </v-col>
                    <v-col cols="9" class="login-col-9">
                      <v-text-field
                        autocomplete="off"
                        label=""
                        class="login-input bg-login-input input-text"
                        v-model="password"
                        min="8"
                        required
                        outlined
                        filled
                        single-line
                        placeholder="password"
                        :type="showPass ? 'text' : 'password'"
                        :rules="passwordValidation"
                      >
                      </v-text-field>
                      <div
                        class="error-message error-message-pink error-message-size"
                        v-if="this.errPassCount > 0"
                      >
                        {{ passValidationMsg }}
                      </div>
                    </v-col>
                    <div
                      id="error-text"
                      class="error-message error-message-pink"
                      v-if="errorText !== '' && !passValidationMsg && !userValidationMsg"
                    >
                      {{ errorText }}
                    </div>
                  </v-row>
                  <div class="row center submit-row">
                    <button
                      type="submit"
                      :class="{
                        'no-cursor': !valid,
                      }"
                      class="button-login login-hover button-text"
                    >
                      Login
                    </button>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Message popup -->
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      content-class="confirm-dialog-box"
    >
      <v-card>
        <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span v-html="apiMessage"></span>
            </v-col>
            <v-col cols="12" class="dialog-buttons-padding text-center">
              <button
                class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                type="button"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <!-- End Message popup -->
  </div>
  
</template>

<style src="../assets/css/login.css"></style>

<script>
import { auth } from "../services/";
export default {
  data() {
    return {
      showPass: false,
      userName: "",
      password: "",
      errorText: "",
      warningMsg: "",
      remainingLockMsg: "",
      lockMsg: "",
      userValidationMsg: "",
      passValidationMsg: "",
      errUserCount: 0,
      errPassCount: 0,
      timeout: 2000,
      valid: false,
      countAttempt: 5,
      t: null,
      messageDialog: false,
      apiTitle: "",
      apiMessage: "",
      isLoading: false,
    };
  },
  computed: {
    userValidation() {
      return [
        (v) => {
          if (this.errUserCount == 0) {
            this.errUserCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.userValidationMsg = "Username is required";
            return false;
          } else {
            this.userValidationMsg = "";
            return true;
          }
        },
      ];
    },
    passwordValidation() {
      return [
        (v) => {
          if (this.errPassCount == 0) {
            this.errPassCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.passValidationMsg = "Password is required";
            return false;
          } else {
            this.passValidationMsg = "";
            return true;
          }
        },
      ];
    },
  },
  methods: {
    validUserName() {
      if (!this.userName) {
        this.valid = false;
        this.userValidationMsg = "Username is required";
        return false;
      }
      this.userValidationMsg = "";
      return true;
    },
    validPassword() {
      if (!this.password) {
        this.valid = false;
        this.passValidationMsg = "Password is required";
        return false;
      }
      this.passValidationMsg = "";
      return true;
    },
    submit() {
      clearTimeout(this.t);
      this.errorText = "";
      if (this.validUserName() && this.validPassword()) {
        this.isLoading = true;
        const username = this.userName;
        const password = this.password;
        const device = "browser";
        auth
          .login({ username, password, device })
          .then((res) => {
            if (res.status == 200 && res.data.success) {
              if (res?.data?.data?.token) {
                if (!res.data.data?.user?.user_site?.length) {
                  this.apiTitle = "Error";
                  this.apiMessage = "No sites for this user were found";
                  this.messageDialog = true;
                } else {
                  this.$store
                    .dispatch("login", res.data.data)
                    .then(() => (this.$router.push("/")));
                }
              } else if (res?.data?.data?.id) {
                this.apiTitle = "Success";
                this.apiMessage = "Password has been reset to default successfully";
                if (res?.data?.message) {
                  console.log(res?.data?.message);
                  this.apiMessage = res?.data?.message;
                }
                this.messageDialog = true;
              } else {
                this.errorText = "The username and /or password you entered is incorrect. Please try again.";
                if (res?.data?.data?.message) {
                  this.errorText = res?.data?.data?.message
                }
                this.snackbar = true;
              }
              this.isLoading = false;
            } else {
              throw res;
            }
          })
          .catch((err) => {
            this.errorText = "The username and /or password you entered is incorrect. Please try again.";
            if (err?.response?.data?.message) {
              this.countAttempt = 0;
              this.errorText = err?.response?.data?.message;
            }
            this.isLoading = false;
          });
      }
    },
    closeMessage() {
      this.messageDialog = false;
      this.apiTitle = "";
      this.apiMessage = "";
    },
  },
};
</script>
